import React from 'react';
import { DashboardProjects } from '@store/dashboard/contracts';
import { projectStatusColors } from './AmountOfProjects';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DictionaryName } from '@store/dictionary/contracts';
import { openModal } from '@store/ui/ui.slice';
import { ProjectsByStatusModal } from './ProjectsByStatusModal';
import styles from './StatusList.module.scss';

type StatusListProps = {
  projects?: DashboardProjects | null;
};

export const StatusList: React.FC<StatusListProps> = ({ projects }) => {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(selectDictionary(DictionaryName.PROJECT_STATUS));
  return (
    <ul className={styles.status__list}>
      {projects !== null && projects?.statusCountList
        ? projects?.statusCountList?.map((project) => (
            <li className={styles.status__item} key={project.statusType}>
              <div
                className={styles.status__color}
                style={{
                  backgroundColor: projectStatusColors.get(project.statusType || 'default'),
                }}></div>
              <span
                onClick={() =>
                  dispatch(
                    openModal(<ProjectsByStatusModal statusName={project.statusType || ''} />),
                  )
                }>
                {`${project.statusType} (${project.taskCount})`}
              </span>
            </li>
          ))
        : statuses.map((status) => (
            <li className={styles.status__item} key={status.id}>
              <div
                className={styles.status__color}
                style={{
                  backgroundColor: projectStatusColors.get(status.name || 'default'),
                }}></div>
              {`${status.name} (0)`}
            </li>
          ))}
    </ul>
  );
};
