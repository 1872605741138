import { PaginationClass, ToDate } from '@store/common';
import { Dayjs } from 'dayjs';
import { Type } from 'class-transformer';
import Api from '@api-schema';

export class Chat {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  name!: string;
  @Type(() => Message)
  messages!: Message[];
  unreadMessagesCount!: number;
  authorName!: string;
  personal!: boolean;
}

export class Message {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  chatId!: string;
  favorite!: boolean;
  text!: string;
  author!: {
    userId: string;
    userName: string;
    storedFileId: string | null;
  };
  @ToDate('DD.MM.YYYY HH:mm')
  date!: Dayjs;
  read!: boolean;
}

export class ChatDetailed {
  chatId!: string;
  chatName!: string;
  @Type(() => MessageList)
  messages!: {
    data: Message[];
    totalCount: number;
  };
}

class MessageList {
  @Type(() => Message)
  data!: Message[];
  totalCount!: number;
}

export class PaginatedChat {
  totalCount!: number;
  @Type(() => Chat)
  data!: Chat[];
}

export type SetFavoriteMessageRequest = {
  id: Api.operations['changeFavoriteStatus_4']['parameters']['path']['id'];
  requestBody: Api.operations['changeFavoriteStatus_4']['requestBody']['content']['application/json'];
};

export class FavoriteMessagesResponse extends PaginationClass {
  @Type(() => Message)
  data!: Message[];
}
