import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import dayjs from 'dayjs';
import { InputField } from '@components/MUI/InputField/InputField';
import { Select } from '@components/MUI/Select';
import { Button } from '@components/MUI/Button';
import styles from './DelegationForm.module.scss';

import {
  loadOrganizations,
  selectOrganizationOptions,
  loadClouds,
  selectCloudOptions,
  loadBillingAccounts,
  selectBillingAccountOptions,
  loadCloudDelegations,
  selectParentCloudDelegationOptions,
  selectParentCloudDelegations,
} from '@store/cloud/cloud.slice';

import { useLazyGetProjectsQuery } from '@store/project/project.slice';
import { Project } from '@store/project/contracts';

import { closeModal, openModal } from '@store/ui/ui.slice';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';
import { FormControlLabel } from '@mui/material';
import { MuiSwitch } from '@components/ui/Switch/MuiSwith';
import { FormField } from '@components/Form/FormField';
import { useParams } from 'react-router-dom';
import { useGetBudgetLimitActionWithParent } from './delegationHooks/useGetBudgetLimitActionWithParent';
import { useSetFieldWithParent } from './delegationHooks/useSetFieldWithParent';
import { CloudDelegation } from '@store/cloud/contracts';

export type DelegationFormProps = {
  value: DelegationFormValue;
  submit: (
    value: DelegationFormValue,
    form: FormikBag<DelegationFormProps, DelegationFormValue>,
  ) => void;
};

export type DelegationFormValue = {
  id: string;
  name: string;
  parentId: string;
  organizationId: string;
  organizationName: string;
  cloudId: string;
  cloudName: string;
  billingAccountId: string;
  billingAccountName: string;
  projectId: string;
  isEnabledCreateInfrastructure: boolean;
  allocateBudget: boolean;
  currencyId: string;
  budget: number;
  budgetLimitActionId: string;
  resetBudgetType: string;
  budgetEndDate: string; //dayjs.Dayjs;
  parent?: CloudDelegation;
  distributed: number;
  svParentId: string;
  svBudget: number;
};

const DelegationFormSchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  organizationId: Yup.string().required('Поле обязательно для заполнения'),
  cloudId: Yup.string(),
  billingAccountId: Yup.string().required('Поле обязательно для заполнения'),
  allocateBudget: Yup.boolean(),
  budget: Yup.number()
    .when('allocateBudget', {
      is: (allocateBudget: boolean) => allocateBudget,
      then: Yup.number().min(1, 'Бюджет должен быть положительным'),
    })
    .test(
      'lessThanParentBudget',
      'Бюджет не должен превышать свободный бюджет основания',
      function (value) {
        const schema = this.parent;
        const svBudget = schema.svParentId === schema.parent.id ? schema.svBudget : 0;
        if (schema.parent.budget && value)
          return value <= schema.parent.budget - schema.parent.distributed + svBudget;
        return true;
      },
    ),
  budgetEndDate: Yup.date()
    .min(dayjs().add(-1, 'day'), `Выберите дату от ${dayjs().add(1, 'day').format('DD.MM.YYYY')}`)
    .nullable()
    .test(
      'lessThanParentBudgetEndDate',
      'Дата окончания бюджета не должна превышать дату окончания бюджета основания',
      function (value) {
        const schema = this.parent;
        if (schema.parent.budgetEndDate && value)
          return dayjs(value) <= dayjs(schema.parent.budgetEndDate, 'DD.MM.YYYY hh:mm');
        return true;
      },
    ),
  parentId: Yup.string(),
  projectId: Yup.string(),
  currencyId: Yup.string().when('allocateBudget', {
    is: (allocateBudget: boolean) => allocateBudget,
    then: Yup.string().required('Поле обязательно для заполнения'),
  }),
  budgetLimitActionId: Yup.string(),
  resetBudgetType: Yup.string(),
  svBudget: Yup.number(),
  svParentId: Yup.number(),
  parent: Yup.object({
    name: Yup.string(),
    organizationId: Yup.string(),
    cloudId: Yup.string(),
    billingAccountId: Yup.string(),
  }).nullable(),
});

const FormComponent = () => {
  const form = useFormikContext<DelegationFormValue>();

  const { delegationId } = useParams();
  const currencies = useDictionary(DictionaryName.CURRENCY, fromDictionaryToSelectOption).filter(
    (currency) => currency.label === 'RUB',
  );
  const budgetLimitAction = useDictionary(
    DictionaryName.CLOUD_BUDGET_LIMIT_ACTION,
    fromDictionaryToSelectOption,
  );
  const resetBudgetTypes = [
    { value: 'NEVER', label: 'Никогда' },
    { value: 'MONTHLY', label: 'Ежемесячно' },
  ];

  const dispatch = useAppDispatch();

  const organizationOptions = useAppSelector(selectOrganizationOptions);
  const cloudOptions = useAppSelector(selectCloudOptions).filter(
    (cloud) => cloud.label !== 'Создать',
  );
  const billingAccountOptions = useAppSelector(selectBillingAccountOptions);
  const { projectList } = useProjectList();

  const cloudDelegationOptions = useAppSelector(
    selectParentCloudDelegationOptions(form.values.id, form.values.svParentId),
  );
  const cloudDelegations = useAppSelector(
    selectParentCloudDelegations(form.values.id, form.values.svParentId),
  );
  form.validationSchema = DelegationFormSchema;

  function useProjectList(): {
    projectList: Project[];
  } {
    const [projectList, setProjectList] = useState<Project[]>([]);
    const [getProjects] = useLazyGetProjectsQuery();

    useEffect(() => {
      getProjects({
        limit: 30,
        sortOrder: 'desc',
        start: 0,
      }).then((project) => setProjectList(project?.data?.data || []));
    }, []);

    return {
      projectList,
    };
  }
  useEffect(() => {
    dispatch(loadCloudDelegations({ limit: 50 }));
    dispatch(
      loadOrganizations({ delegationId: form.values.parentId == null ? '' : form.values.parentId }),
    );
    dispatch(
      loadBillingAccounts({
        delegationId: form.values.parentId == null ? '' : form.values.parentId,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      loadOrganizations({ delegationId: form.values.parentId == null ? '' : form.values.parentId }),
    );
    dispatch(
      loadBillingAccounts({
        delegationId: form.values.parentId == null ? '' : form.values.parentId,
      }),
    );
    if (form.values.id === '') {
      form.setFieldValue('organizationId', '');
      form.setFieldValue('cloudId', '');
      form.setFieldValue('billingAccountId', '');
    }
  }, [form.values.parentId]);

  useEffect(() => {
    dispatch(
      loadClouds({
        delegationId: form.values.parentId == null ? '' : form.values.parentId,
        parentId: form.values.organizationId,
      }),
    );
    let organizationName = '';
    if (form.values.organizationId) {
      let selected = organizationOptions.find((item) => item.value === form.values.organizationId);
      if (selected !== undefined) organizationName = selected.label;
    }
    form.setFieldValue('organizationName', organizationName);
  }, [form.values.organizationId]);

  useEffect(() => {
    let cloudName = '';
    if (form.values.cloudId) {
      let selected = cloudOptions.find((item) => item.value === form.values.cloudId);
      if (selected !== undefined) cloudName = selected.label;
    }
    form.setFieldValue('cloudName', cloudName);
  }, [form.values.cloudId]);

  useEffect(() => {
    let billingAccountName = '';
    if (form.values.billingAccountId) {
      let selected = billingAccountOptions.find(
        (item) => item.value === form.values.billingAccountId,
      );
      if (selected !== undefined) billingAccountName = selected.label;
    }
    form.setFieldValue('billingAccountName', billingAccountName);
  }, [form.values.billingAccountId]);

  useEffect(() => {
    if (
      form.values.budgetEndDate !== undefined ||
      form.values.budgetEndDate !== null ||
      form.values.budgetEndDate === ''
    ) {
      const currBudgetEndDate = dayjs(form.values.budgetEndDate);
      const budgetEndDate = currBudgetEndDate.endOf('month');
      if (budgetEndDate.format('DD.MM.YYYY') !== currBudgetEndDate.format('DD.MM.YYYY'))
        form.setFieldValue('budgetEndDate', budgetEndDate.format('YYYY-MM-DD'));
    }
  }, [form.values.budgetEndDate]);

  const cloudDelegation = useMemo(() => {
    const parentId = form.values.parentId;
    return cloudDelegations?.find((delegation) => delegation.id === parentId);
  }, [form.values.parentId]);

  useSetFieldWithParent(form, cloudDelegation);

  useEffect(() => {
    if (cloudDelegation) {
      form.setFieldValue('parent', cloudDelegation);
    }
  }, [cloudDelegation]);

  const conditionalOrganizationOptions = useMemo(() => {
    const allOrganizationOptions = organizationOptions;
    cloudDelegations.forEach((delegation) => {
      allOrganizationOptions.push({
        label: delegation.organization?.resourceName || '',
        value: delegation.organization?.resourceId || '',
      });
    });

    if (form.values.parentId !== '') return allOrganizationOptions;
    return organizationOptions;
  }, [form.values.parentId]);

  const conditionalCloudOptions = useMemo(() => {
    const allCloudOptions = cloudOptions;
    cloudDelegations.forEach((delegation) => {
      allCloudOptions.push({
        label: delegation.cloud?.resourceName || '',
        value: delegation.cloud?.resourceId || '',
      });
    });

    if (form.values.parentId) return allCloudOptions;
    return cloudOptions;
  }, [form.values.parentId]);

  const conditionalBillingAccountOptions = useMemo(() => {
    const allBillingAccountsOptions = billingAccountOptions;
    cloudDelegations.forEach((delegation) => {
      allBillingAccountsOptions.push({
        value: delegation.billingAccount?.resourceId || '',
        label: delegation.billingAccount?.resourceName || '',
      });
    });

    if (form.values.parentId) return allBillingAccountsOptions;
    return billingAccountOptions;
  }, [form.values.parentId]);

  const resetBudgetTypesWithParent = useMemo(() => {
    if (cloudDelegation) {
      if (cloudDelegation.resetBudgetType === 'NEVER') {
        return [{ value: 'NEVER', label: 'Никогда' }];
      }
      if (cloudDelegation.resetBudgetType === 'MONTHLY') {
        return [
          { value: 'NEVER', label: 'Никогда' },
          { value: 'MONTHLY', label: 'Ежемесячно' },
        ];
      }
    }
    return [
      { value: 'NEVER', label: 'Никогда' },
      { value: 'MONTHLY', label: 'Ежемесячно' },
    ];
  }, [cloudDelegation]);

  const optionalBudgetLimitAction = useGetBudgetLimitActionWithParent(
    budgetLimitAction,
    form,
    cloudDelegation,
  );
  const disableAllocateBudget = useCallback(async () => {
    form.setFieldValue('allocateBudget', false);
    dispatch(closeModal());
  }, []);

  const confirmTurnOffAllocateBudget = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите отключить бюджет?"
          btnProp="Отключить"
          onSubmit={disableAllocateBudget}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  const toggleAllocateBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      confirmTurnOffAllocateBudget();
    } else form.setFieldValue('allocateBudget', event.target.checked);
  };

  return (
    <Form onSubmit={form.handleSubmit}>
      <div className={styles.form__wrapper}>
        <InputField
          name="name"
          placeholder="Название делегата"
          type="text"
          className={styles.form__field}
        />

        <Select
          name="parentId"
          values={cloudDelegationOptions}
          label="Основание"
          isOutlined
          className={styles.form__field}
        />

        <Select
          name="organizationId"
          values={form.values.parentId ? conditionalOrganizationOptions : organizationOptions}
          label="Организация"
          isOutlined
          className={styles.form__field}
          disabled={!!form.values.parentId}
        />

        <Select
          name="billingAccountId"
          values={form.values.parentId ? conditionalBillingAccountOptions : billingAccountOptions}
          label="Платежный аккаунт"
          isOutlined
          className={styles.form__field}
          disabled={!!form.values.parentId}
        />
        <Select
          name="cloudId"
          values={form.values.parentId ? conditionalCloudOptions : cloudOptions}
          label="Облако"
          isOutlined
          className={styles.form__field}
          disabled={!!form.values.parentId}
        />
        <Select
          values={
            projectList?.map((project) => ({
              value: project.id,
              label: project.name,
            })) || []
          }
          disabled={projectList.length === 0 || !!form.values.parentId}
          name={'projectId'}
          label={projectList.length === 0 ? 'Создайте заявку и переведите в проект' : 'Проект'}
          isOutlined={true}
        />
        <div className="d-flex gap-4 align-items-center">
          Выделить бюджет
          <FormControlLabel
            control={
              <MuiSwitch
                checked={form.values.allocateBudget}
                onChange={toggleAllocateBudget}
                name="allocateBudget"
              />
            }
            disabled={cloudDelegation?.currency === null ? true : false}
            label=""
          />
        </div>
        <br />
        {form.values.allocateBudget && (
          <>
            <Select
              name={'currencyId'}
              values={currencies}
              label={'Валюта'}
              isOutlined
              value={form.values.currencyId}
              className={styles.form__item}
              disabled={cloudDelegation?.currency ? true : false}
            />
            <br />
            <InputField
              name="budget"
              type="number"
              placeholder="Бюджет"
              className={styles.form__item}
            />
            <br />

            <Select
              name={'budgetLimitActionId'}
              values={optionalBudgetLimitAction}
              label={'Действие при достижении суммы'}
              isOutlined
              value={form.values.budgetLimitActionId}
              className={styles.form__item}
            />
            <br />
            <Select
              name={'resetBudgetType'}
              values={cloudDelegation ? resetBudgetTypesWithParent : resetBudgetTypes}
              label={'Период сброса бюджетного правила'}
              isOutlined
              value={form.values.resetBudgetType}
              className={styles.form__item}
            />
            <br />
            <FormField
              name="budgetEndDate"
              type="date"
              placeholder="Дата окончания бюджета"
              className="mb-4 input-classical"
              floating
              floatingLabelClassName="mb-3 req-label-custom"
            />
            <br />
          </>
        )}
      </div>
      <Button
        type="submit"
        disabled={!form.isValid}
        color="success"
        variant="contained"
        size="large"
        className={styles.submit__button}>
        {delegationId ? 'Сохранить' : 'Делегировать'}
      </Button>
      <RequiredFieldsIndicator />
    </Form>
  );
};

export const DelegationForm = withFormik<DelegationFormProps, DelegationFormValue>({
  displayName: 'DelegationForm',
  mapPropsToValues: (props) => {
    return {
      id: props.value.id,
      name: props.value.name,
      parentId: props.value.parentId,
      organizationId: props.value.organizationId,
      organizationName: props.value.organizationName,
      cloudId: props.value.cloudId,
      cloudName: props.value.cloudName,
      billingAccountId: props.value.billingAccountId,
      billingAccountName: props.value.billingAccountName,
      projectId: props.value.projectId,
      isEnabledCreateInfrastructure: props.value.isEnabledCreateInfrastructure,
      allocateBudget: props.value.allocateBudget,
      currencyId: props.value.currencyId,
      budget: props.value.budget,
      budgetLimitActionId: props.value.budgetLimitActionId,
      resetBudgetType: props.value.resetBudgetType,
      budgetEndDate: props.value.budgetEndDate,
      parent: undefined,
      distributed: props.value.distributed,
      svParentId: props.value.svParentId,
      svBudget: props.value.svBudget,
    };
  },
  enableReinitialize: true,
  validationSchema: DelegationFormSchema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
