import { api } from '@store/api-client';
import { DocumentsResponse } from './contracts';
import { PathParameters, RequestBody, ResponseBody } from '@store/utility';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { enqueueSnackbar } from 'notistack';

const docsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDocsFavorite: builder.query<
      DocumentsResponse,
      RequestBody<Api.operations['getOrderProjects']>
    >({
      query: (requestBody) => ({
        method: `POST`,
        url: `/api/document/favorite`,
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getOrderProjects']>) => {
        return plainToInstance(DocumentsResponse, response);
      },
      providesTags: ['FavoriteDocs'],
    }),

    setDocsFavorite: builder.mutation<
      ResponseBody<Api.operations['changeFavoriteStatus_3']>,
      {
        requestBody: RequestBody<Api.operations['changeFavoriteStatus_3']>;
        docId: PathParameters<Api.operations['changeFavoriteStatus_3'], 'id'>;
      }
    >({
      query: ({ docId, requestBody }) => ({
        method: `PUT`,
        url: `/api/file/${docId}/favorite`,
        body: requestBody,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Раздел "Избранное" успешно обновлен', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['FavoriteDocs', 'ProjectDetail'],
    }),
  }),
});

export const { useGetDocsFavoriteQuery, useSetDocsFavoriteMutation } = docsApi;
