import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Template } from '@store/cloud/contracts';
import { Button } from '@components/MUI/Button';
import { InputField } from '@components/MUI/InputField/InputField';
import styles from './TemplateForm.module.scss';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';

export type TemplateFormProps = {
  value: Partial<Template>;
  submit: (value: Partial<Template>, form: FormikBag<TemplateFormProps, Partial<Template>>) => void;
};

const TemplateFormSchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  memory: Yup.number()
    .required('Поле обязательно для заполнения')
    .min(1, 'Память должна быть положительной'),
  cores: Yup.number()
    .required('Поле обязательно для заполнения')
    .min(2, 'Минимальное количество ядер 2'),
  diskSize: Yup.number()
    .required('Поле обязательно для заполнения')
    .min(1, 'Размер диска должн быть положительной'),
});

const TemplateFormComponent: React.FC<TemplateFormProps> = (props: TemplateFormProps) => {
  const templateForm = useFormikContext();
  const { value } = props;

  templateForm.validationSchema = TemplateFormSchema;

  return (
    <Form onSubmit={templateForm.handleSubmit} className={styles.template__form}>
      <InputField
        name="name"
        type="text"
        placeholder="Название шаблона"
        className={styles.input__field}
        fullWidth
      />
      <InputField
        name="memory"
        type="number"
        placeholder="Память"
        className={styles.input__field}
      />
      <InputField
        name="diskSize"
        type="number"
        placeholder="Дисковое пространство"
        className={styles.input__field}
      />
      <InputField name="cores" type="number" placeholder="Ядро" className={styles.input__field} />
      <div className={'mt-3'}>
        <Button type="submit" variant="contained" size="large" disabled={!templateForm.isValid}>
          {value.id ? 'Сохранить' : 'Создать'}
        </Button>
      </div>
      <RequiredFieldsIndicator />
    </Form>
  );
};

export const TemplateForm = withFormik<TemplateFormProps, Partial<Template>>({
  displayName: 'TemplateModal',
  mapPropsToValues: (props) => {
    return {
      name: props.value.name,
      memory: props.value.memory,
      cores: props.value.cores,
      diskSize: props.value.diskSize,
    };
  },
  enableReinitialize: true,
  validationSchema: TemplateFormSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(TemplateFormComponent);
