import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Box, Divider, InputAdornment, Modal, TextField } from '@mui/material';
import { closeModal, isModalOpen } from '@store/ui/ui.slice';
import { FormWrapper } from '@components/Form/Form';
import { Link } from 'react-router-dom';
import { ReactComponent as SearchGray } from '@assets/img/searchGray.svg';
import { debounce } from 'lodash';
import styles from './ProjectsByStatusModal.module.scss';
import { useGetProjectsQuery } from '@store/project/project.slice';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { DictionaryName } from '@store/dictionary/contracts';
import { CircularProgress } from '@mui/material';

type ProjectsByStatusModalProps = {
  statusName: string;
};

export const ProjectsByStatusModal: React.FC<ProjectsByStatusModalProps> = ({ statusName }) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isModalOpen);
  const [filterValue, setFilterValue] = useState<string>('');
  const statuses = useAppSelector(selectDictionary(DictionaryName.PROJECT_STATUS));
  const statusId = statuses.find((status) => status.name === statusName);
  const statusProjectId = statusId ? statusId.id : '';
  const { data: projects, isLoading } = useGetProjectsQuery({
    start: 0,
    limit: 10,
    sortOrder: 'desc',
    projectStatusId: [statusProjectId],
    projectName: filterValue,
  });

  const handleFilterChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFilterValue(e.target.value);
    },
    500,
  );

  return (
    <Modal open={isOpen} onClose={() => dispatch(closeModal())} className={styles.modal}>
      <Box className={styles.container}>
        <FormWrapper label={`Проекты ${statusName}`} titleClassName={styles.title}>
          <TextField
            size="small"
            placeholder="Поиск"
            className={styles.filter__input}
            onChange={handleFilterChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchGray />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <Divider className={styles.divider} />
          <ul className={styles.projects__list}>
            {!isLoading ? (
              projects?.data?.map((project, index, projects) => (
                <>
                  <li className={styles.project__item}>
                    <Link
                      to={`/projects/${project.id}/details`}
                      onClick={() => dispatch(closeModal())}>
                      {project.name}
                    </Link>
                  </li>
                  {index !== projects.length - 1 ? <Divider className={styles.divider} /> : <></>}
                </>
              ))
            ) : (
              <div style={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            )}
          </ul>
        </FormWrapper>
      </Box>
    </Modal>
  );
};
