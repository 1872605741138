import styles from './ParticipantsTable.module.scss';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { Link } from 'react-router-dom';
import { Project } from '@store/project/contracts';
import { ReactComponent as MessageIcon } from '@assets/img/messageIcon.svg';
import { useNavigateToUserChat } from '@hooks/useNavigateToUserChat';
import { Button } from '@components/MUI/Button';
import { DropdownMenu } from '@components/ui/DropdownMenu';
import { IconButton } from '@components/ui/Button/IconButton';

type ParticipantsTableProps = {
  participants: Project['teamPositions'];
  isManager: boolean;
};

export const ParticipantsTable = ({ participants, isManager }: ParticipantsTableProps) => {
  const createChat = useNavigateToUserChat();

  const handleChatClick = (id: string) => {
    createChat(id);
  };

  return (
    <div className={styles.container}>
      <h4>Исполнители</h4>
      <AdaptiveTableMUI
        emptyLabel="Участники отсутствуют"
        rows={participants}
        columns={{
          userName: {
            label: 'Исполнитель',
            children: (value, row) => (
              <Link to={`/userPage/${row.userId}`} className={styles.executor}>
                {value}
              </Link>
            ),
          },
          positionName: {
            label: 'Должность',
          },
          userTelegram: {
            label: 'Ник в Telegram',
          },
          userId: {
            label: '',
            children: (value) => (
              <IconButton onClick={() => handleChatClick(value)}>
                <MessageIcon />
              </IconButton>
            ),
          },
          ...(isManager && {
            more: {
              label: '',
              children: () => (
                <div className={styles.more}>
                  <div>
                    <DropdownMenu>
                      <Button variant="text" className={styles.more_option}>
                        Открепить исполнителя
                      </Button>
                    </DropdownMenu>
                  </div>
                </div>
              ),
            },
          }),
        }}
      />
    </div>
  );
};
