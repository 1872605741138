import { RequestParams, TableHeadCell } from './TableHeadCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Columns } from './TableMUI';
import classNames from 'classnames';
import styles from './Table.module.scss';

type HeadTypes = {
  columns: Record<string | symbol, Columns>;
  setRequestBody?: (value: Record<string, any>) => void;
  filter?: Record<string, RequestParams>;
  sortOrder?: 'asc' | 'desc';
  setSortOrder?: (sortOrder: 'asc' | 'desc') => void;
  sortColumn?: string;
  setSortColumn?: (column: string) => void;
};

export const Head = ({
  columns,
  setRequestBody,
  filter = {},
  setSortColumn,
  setSortOrder,
  sortOrder,
  sortColumn,
}: HeadTypes) => {
  const [requestParams, setRequestParams] = useState<Record<string, RequestParams>>(filter);

  const filterChange = useCallback((column: string) => {
    return function (filterInput: RequestParams) {
      setRequestParams((prevFilter) => {
        for (let column in prevFilter) {
          Object.defineProperty(prevFilter, column, {
            value: { ...prevFilter[column], sort: false },
          });
        }
        const newParams = {
          ...prevFilter,
          [column]: filterInput,
        };

        return newParams;
      });
    };
  }, []);

  useEffect(() => {
    if (requestParams !== filter) {
      setRequestBody?.(requestParams);
    }
  }, [requestParams]);

  const cellHasInput = useMemo(() => {
    return Object.keys(columns).some((column) => columns[column].filterInput);
  }, [columns]);

  return (
    <TableHead>
      <TableRow
        className={classNames({
          [styles.withInput]: cellHasInput,
        })}>
        {Object.keys(columns).map((column) => {
          return (
            <TableCell key={column}>
              <div
                className={classNames({
                  [styles.withInput__cell]: cellHasInput,
                })}>
                <TableHeadCell
                  value={filter?.[column]}
                  onChange={filterChange(column)}
                  label={columns?.[column].label}
                  isSortColumn={columns[column].isSortColumn}
                  isFilterColumn={columns[column].filterInput}
                  isCheckboxColumn={columns[column].checkboxInput}
                  column={column}
                  setSortColumn={setSortColumn}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  sortColumn={sortColumn}
                  additionalItem={columns[column].additionalItem}
                />
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
