import { ReactComponent as MoreIcon } from '@assets/img/moreVertical.svg';
import { Menu } from '@mui/material';
import { ReactNode, useState } from 'react';
import styles from './DrowdownMenu.module.scss';
import { IconButton } from '../Button/IconButton';

type DropdownMenuProps = {
  children: ReactNode;
};

export const DropdownMenu = ({ children }: DropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreIcon />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiList-root': {
            borderRadius: '3px',
            padding: 0,
            border: '1px solid #DCDCDC',
          },
          '& .MuiPaper-root': {
            boxShadow: 'none',
          },
        }}>
        <div className={styles.menu}>{children}</div>
      </Menu>
    </>
  );
};
