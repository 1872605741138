import { useSearchParams } from 'react-router-dom';

export function useUrlParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParam = (key: string, defaultValue: any = null): any => {
    const value = searchParams.get(key);
    return value !== null ? value : defaultValue;
  };

  const updateParams = (newParams: Record<string, any>) => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      Object.entries(newParams).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          updatedParams.set(key, value.toString());
        } else {
          updatedParams.delete(key);
        }
      });
      return updatedParams;
    });
  };

  return {
    getParam,
    updateParams,
  };
}
