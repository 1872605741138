import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { usePagination } from '@hooks/usePagination';
import { useRequestBody } from '@hooks/useRequestBody';
import { getCloudScheduleList } from '@store/cloud/cloud.service';
import { CloudSchedule } from '@store/cloud/contracts';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';

const Schedules = () => {
  const dispatch = useAppDispatch();
  const [schedules, setSchedules] = useState<CloudSchedule[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const {
    data: fetchedData,
    total,
    loading,
  } = usePagination({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    accumulate: false,
    action: (query) => getCloudScheduleList(query),
    additionalParams: requestBody,
  });

  useEffect(() => {
    setSchedules(fetchedData);
  }, [dispatch, fetchedData]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className="col">
      <div className="mt-4">
        <AdaptiveTableMUI
          emptyLabel="У вас нет делегатов"
          setRequestBody={setRequestBody}
          setSortColumn={setSortColumn}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          isLoading={loading}
          columns={{
            name: {
              label: 'Название',
              filterInput: true,
              children: (value, row) => (
                <NavLink to={`/clouds/schedules/${row?.id}`}>{value}</NavLink>
              ),
            },
            startDate: {
              label: 'Дата начала',
              filterInput: true,
              children: (_, row) => row?.startDate,
            },
            endDate: {
              label: 'Дата окончания',
              filterInput: true,
              children: (_, row) => row?.endDate,
            },
          }}
          rows={schedules}>
          <TablePaginationMUI
            limit={limit}
            count={total}
            entityLabel="расписаний"
            page={page}
            handleChangePage={(_, newPage) => setPage(newPage)}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </AdaptiveTableMUI>
      </div>
    </div>
  );
};

export { Schedules };
