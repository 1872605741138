import { ScheduleForm, ScheduleFormProps } from '@pages/cloud/schedules/ScheduleForm';
import { useAppDispatch } from '@hooks/index';
import { useNavigate } from 'react-router-dom';
import { createSchedule } from '@store/cloud/cloud.slice';
import dayjs from 'dayjs';
import { Card } from '@mui/material';
import classNames from 'classnames';
import styles from './ScheduleForm.module.scss';

function CreateScheduleForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createSaveSchedule: ScheduleFormProps['submit'] = async (ScheduleForm, form) => {
    try {
      await dispatch(
        createSchedule({
          name: ScheduleForm.name,
          startDate: dayjs(ScheduleForm.startDate).format('DD.MM.YYYY HH:mm'),
          endDate: dayjs(ScheduleForm.endDate).format('DD.MM.YYYY HH:mm'),
        }),
      ).unwrap();
      navigate(`/clouds/schedules`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  return (
    <Card
      className={classNames('form-container', styles.form__card)}
      elevation={0}
      variant="outlined">
      <div className={'row'}>
        <div className={styles.form__page__container}>
          <div className={styles.form__page__title}>
            <h2 className={'form-title'}>Расписание</h2>
          </div>
          <ScheduleForm
            value={{
              id: '',
              name: '',
              startDate: '',
              endDate: '',
            }}
            submit={createSaveSchedule}
          />
        </div>
      </div>
    </Card>
  );
}

export { CreateScheduleForm };
