import { Modal, ModalFooter, ModalProps } from '@components/MUI/Modal/Modal';
import '../../../scss/components/modal.scss';
import { ReactNode, useCallback } from 'react';
import { FormWrapper } from '@components/Form/Form';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { Button } from '@components/MUI/Button';
import styles from './AlertModal.module.scss';

interface AlertModalProps {
  modalProps?: ModalProps;
  title: string;
  children: ReactNode;
  btnProp?: string;
}

export const AlertModal = ({ modalProps, title, children, btnProp }: AlertModalProps) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal {...modalProps}>
      <FormWrapper label={title} isThinTitle isSmallModal>
        <div>{children}</div>
        <ModalFooter>
          <Button className={`me-3 fw-bold ${styles.cancelBtn}`} onClick={handleCloseModal}>
            {btnProp || 'Закрыть'}
          </Button>
        </ModalFooter>
      </FormWrapper>
    </Modal>
  );
};
