import React, { useEffect, useMemo, useState } from 'react';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { useRequestBody } from '@hooks/useRequestBody';
import { Task } from '@store/tasks/contracts';
import { Link, useOutletContext } from 'react-router-dom';
import { StatusLabel, statusTaskColors } from '@components/ui/StatusLabel/StatusLabel';
import { Dayjs } from 'dayjs';
import { TaskContext } from '@layouts/task/TasksLayout';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { CreateTaskButton } from './CreateTaskButton';
import { useGetTasksQuery } from '@store/tasks/tracker.slice';
import styles from './TasksList.module.scss';
import { useUrlParams } from '@hooks/useUrlParams';

export const TasksList: React.FC = () => {
  const { getParam, updateParams } = useUrlParams();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [limit, setLimit] = useState<number>(Number(getParam('limit', 10)));
  const [page, setPage] = useState<number>(Number(getParam('page', 1)));
  const { setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder, requestBody } =
    useRequestBody();
  const [context] = useOutletContext<TaskContext>();

  const projectIds = useMemo(() => {
    const projectIds = context.projectIds;
    const projectIdFromUrl = getParam('project');
    if (projectIdFromUrl) {
      return [projectIdFromUrl];
    }

    if (context.projectIds?.length && context.projectIds?.length === 0) {
      return undefined;
    }
    return projectIds;
  }, [context.projectIds]);

  const query = useMemo(() => {
    return Object.keys(requestBody).reduce((acc: { [key: string]: string }, key: string) => {
      if (requestBody[key].value !== undefined) {
        acc[key] = requestBody[key].value!;
      }
      return acc;
    }, {});
  }, [requestBody]);

  const { data: fetchedData, isLoading } = useGetTasksQuery({
    limit: limit,
    start: (page - 1) * limit,
    projects: projectIds,
    ...(sortColumn && { sortField: sortColumn }),
    sortOrder: sortOrder,
    ...query,
  });

  useEffect(() => {
    if (!getParam('project') && projectIds?.length) {
      const projectId = projectIds[0];
      setLimit(10);
      setPage(1);
      updateParams({ limit: 10, page: 1, project: projectId });
    } else {
      setLimit(10);
      setPage(1);
    }
  }, [projectIds, getParam, updateParams]);

  useEffect(() => {
    if (projectIds?.length && projectIds?.length > 0) {
      setLimit(10);
      setPage(1);
    }
  }, [projectIds]);

  useEffect(() => {
    setTasks(fetchedData?.data || []);
  }, [fetchedData]);

  useEffect(() => {
    const pageFromUrl = getParam('page');
    const limitFromUrl = getParam('limit');
    if (pageFromUrl && limitFromUrl) {
      setPage(Number(pageFromUrl));
      setLimit(Number(limitFromUrl));
    }
  }, [getParam]);

  const handleChangePage = (_: unknown, newPage: number) => {
    updateParams({ page: newPage });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newLimit: number) => {
    updateParams({ limit: newLimit, page: 1 });
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className="col">
      <div className="mt-4">
        <AdaptiveTableMUI
          setRequestBody={setRequestBody}
          setSortColumn={setSortColumn}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          isLoading={isLoading}
          columns={{
            key: {
              label: 'Ключ Задачи',
              children: (value, row: Task) => <Link to={`/tasks/${row.id}`}>{value}</Link>,
              filterInput: true,
              isSortColumn: false,
            },
            name: {
              label: 'Задача',
              children: (value, row: Task) => (
                <Link className={styles.task_name} to={`/tasks/${row.id}`}>
                  {value}
                </Link>
              ),
              filterInput: true,
              isSortColumn: false,
            },
            status: {
              label: 'Статус',
              children: (value: any, row: Task) => (
                <StatusLabel text={value?.display} color={statusTaskColors.get(row?.statusType)} />
              ),
              filterInput: true,
              isSortColumn: false,
            },
            executor: {
              label: 'Исполнитель',
              children: (value) => <Link to={`/userPage/`}>{value}</Link>,
              filterInput: true,
              isSortColumn: false,
            },
            created: {
              label: 'Дата создания',
              format: (value: Dayjs) => value.format('DD.MM.YYYY'),
              filterInput: true,
              isSortColumn: true,
            },
            deadline: {
              label: 'Дата завершения',
              format: (value: Dayjs) => (value ? value?.format('DD.MM.YYYY') : '-'),
              filterInput: true,
              isSortColumn: true,
            },
          }}
          rows={tasks}
          emptyLabel={'У вас пока нет задач'}
          additionalElement={<CreateTaskButton />}>
          <TablePaginationMUI
            limit={limit}
            count={fetchedData?.totalCount}
            entityLabel="задач"
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </AdaptiveTableMUI>
      </div>
    </div>
  );
};
