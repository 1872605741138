import { CloudDelegation } from '@store/cloud/contracts';
import { FormikContextType } from 'formik';
import { useEffect } from 'react';
import { DelegationFormValue } from '../DelegationForm';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

export function useSetFieldWithParent(
  form: FormikContextType<DelegationFormValue>,
  cloudDelegation?: CloudDelegation,
) {
  const { delegationId } = useParams();

  useEffect(() => {
    if (cloudDelegation) {
      form.setFieldValue('organizationId', cloudDelegation.organization?.resourceId);
      form.setFieldValue('cloudId', cloudDelegation.cloud?.resourceId);
      form.setFieldValue('billingAccountId', cloudDelegation.billingAccount?.resourceId);
      form.setFieldValue('projectId', cloudDelegation.project?.id);

      if (cloudDelegation.currency) {
        form.setFieldValue('currencyId', cloudDelegation.currency.id);
      }
      if (cloudDelegation.resetBudgetType) {
        form.setFieldValue('resetBudgetType', cloudDelegation.resetBudgetType);
      }
      form.setFieldValue('allocateBudget', cloudDelegation.allocateBudget);
      form.setFieldValue(
        'budgetEndDate',
        cloudDelegation?.budgetEndDate
          ? dayjs(cloudDelegation?.budgetEndDate, 'DD.MM.YYYY hh:mm').format('YYYY-MM-DD')
          : '',
      );
    }
    if (!cloudDelegation && !delegationId) {
      form.setFieldValue('organizationId', '');
      form.setFieldValue('cloudId', '');
      form.setFieldValue('billingAccountId', '');
      form.setFieldValue('projectId', '');
      form.setFieldValue('budgetEndDate', '');
    }
  }, [cloudDelegation]);
}
