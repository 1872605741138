import React, { useEffect, useRef, useState } from 'react';
import styles from './ScheduleForm.module.scss';
import { Card, Skeleton } from '@mui/material';
import classNames from 'classnames';
import { ScheduleForm, ScheduleFormProps } from './ScheduleForm';
import { useAppDispatch } from '@hooks/index';
import { useNavigate, useParams } from 'react-router-dom';
import { editSchedule } from '@store/cloud/cloud.slice';
import { CloudSchedule } from '@store/cloud/contracts';
import { getCloudSchedule } from '@store/cloud/cloud.service';
import dayjs from 'dayjs';

export const EditScheduleForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { scheduleId } = useParams();
  const fetched = useRef(false);

  const [schedule, setSchedule] = useState<CloudSchedule | null>(null);

  useEffect(() => {
    if (!fetched.current) {
      (async () => {
        if (scheduleId) {
          const item = await getCloudSchedule(scheduleId);
          setSchedule(item);
        }
      })();
      fetched.current = true;
    }
  }, [dispatch]);

  const editSaveSchedule: ScheduleFormProps['submit'] = async (ScheduleForm, form) => {
    try {
      await dispatch(
        editSchedule({
          id: scheduleId,
          name: ScheduleForm.name,
          startDate: dayjs(ScheduleForm.startDate).format('DD.MM.YYYY HH:mm'),
          endDate: dayjs(ScheduleForm.endDate).format('DD.MM.YYYY HH:mm'),
        }),
      ).unwrap();
      navigate(`/clouds/schedules/${scheduleId}`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  if (!schedule) {
    return <Skeleton />;
  }

  return (
    <Card
      className={classNames('form-container', styles.form__card)}
      elevation={0}
      variant="outlined">
      <div className={'row'}>
        <div className={styles.form__page__container}>
          <div className={styles.form__page__title}>
            <h2 className={'form-title'}>Редактирование расписания</h2>
          </div>
          <ScheduleForm
            value={{
              id: schedule.id,
              name: schedule.name,
              startDate: schedule?.startDate
                ? dayjs(schedule?.startDate, 'DD.MM.YYYY hh:mm').format('YYYY-MM-DD hh:mm')
                : '',
              endDate: schedule?.endDate
                ? dayjs(schedule?.endDate, 'DD.MM.YYYY hh:mm').format('YYYY-MM-DD hh:mm')
                : '',
            }}
            submit={editSaveSchedule}
          />
        </div>
      </div>
    </Card>
  );
};
