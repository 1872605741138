import {
  BurnoutAnalyticResponse,
  CommonAnalytics,
  ConnectToTrackerMap,
  CycleTimeAnalyticResponse,
  DisconnectTrackerPayload,
  EffectivenessAnalyticResponse,
  GetProjectsResponse,
  GetTeamAnalyticResponse,
  LeadTimeAnalyticResponse,
  Project,
  ThroughputAnalyticResponse,
  WIPAnalyticsResponse,
  WastedTimeAnalyticResponse,
} from '@store/project/contracts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Api from '@api-schema';
import { api } from '@store/api-client';
import { PathParameters, RequestBody, ResponseBody } from '@store/utility';
import { plainToInstance } from 'class-transformer';
import { enqueueSnackbar } from 'notistack';
import { TrackerExecutor } from '@store/tasks/contracts';
import { INTEGRATION_PROVIDER } from '@store/user/contracts';

interface ProjectState {
  projects: Project[];
  currentProject: Project | null;
}

const initialState: ProjectState = {
  projects: [],
  currentProject: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<Project>) => {
      state.currentProject = action.payload;
    },
    setProjects: (state, action: PayloadAction<{ projects: Project[] }>) => {
      state.projects = [...action.payload.projects];
    },
  },
});

export const projectReducer = projectSlice.reducer;

const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTeamAnalytic: builder.query<
      GetTeamAnalyticResponse,
      {
        projectId: PathParameters<Api.operations['getTeamAnalytic'], 'projectId'>;
        requestBody: RequestBody<Api.operations['getTeamAnalytic']>;
      }
    >({
      query: ({ projectId, requestBody }) => ({
        url: `/api/project/${projectId}/report/team`,
        method: 'POST',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getTeamAnalytic']>) => {
        return plainToInstance(GetTeamAnalyticResponse, response);
      },
      providesTags: ['EmployeesAnalytics'],
    }),

    detailProject: builder.query<
      Project,
      PathParameters<Api.operations['getProjectDetail'], 'projectId'>
    >({
      query: (id) => ({
        url: `/api/project/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseBody<Api.operations['getProjectDetail']>) => {
        return plainToInstance(Project, response);
      },
      providesTags: ['ProjectDetail'],
    }),

    getCustomerProjects: builder.query<
      GetProjectsResponse,
      RequestBody<Api.operations['getOrderProjects']>
    >({
      query: (requestBody) => ({
        url: '/api/project/order/list',
        method: 'POST',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getOrderProjects']>) => {
        return plainToInstance(GetProjectsResponse, response);
      },
      providesTags: ['CustomerProjects'],
    }),

    getProjects: builder.query<
      GetProjectsResponse,
      RequestBody<Api.operations['getProjectsInWhichUserParticipates']> & { roles?: string[] } // temp
    >({
      query: (requestBody) => ({
        url: '/api/project/list',
        method: 'POST',
        body: requestBody,
      }),
      transformResponse: (
        response: ResponseBody<Api.operations['getProjectsInWhichUserParticipates']>,
      ) => {
        return plainToInstance(GetProjectsResponse, response);
      },
      providesTags: ['ProjectList'],
    }),

    updateProject: builder.mutation<any, RequestBody<Api.operations['updateRequest']>>({
      query: (requestBody) => ({
        url: 'api/project',
        method: 'PATCH',
        body: requestBody,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Проект отредактирован', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: [
        'ProjectDetail',
        'ProjectList',
        'ManagerProjects',
        'CustomerProjects',
        'ExecutorProjects',
        'ManagerCustomerProjects',
        'DashboardProjectStatuses',
        'DashboardTimeAnalytics',
        'DashboardTaskStatuses',
      ],
    }),

    //TODO: Статусов у проекта в ближайшее время  не будет, поэтому метод getProjectStatuses
    // временно комментирую, потом либо избавимся, либо статусы видоизменятся, не знаю...
    // getProjectStatuses: builder.query<
    //   ProjectStatusesResponse[],
    //   PathParameters<Api.operations['getAvailableStatuses'], 'projectId'>
    // >({
    //   query: (projectId) => ({
    //     url: `/api/project/${projectId}/statuses`,
    //     method: 'GET',
    //   }),
    //   transformResponse: (response: ResponseBody<Api.operations['getAvailableStatuses']>) => {
    //     return plainToInstance(ProjectStatusesResponse, response);
    //   },
    //   providesTags: ['ProjectStatuses'],
    // }),

    connectYandexTrackerToProject: builder.mutation<
      ResponseBody<Api.operations['connectProjectToYandexTracker']>,
      {
        projectId: string;
        payload: {
          organizationId: string;
          externalProjectId: string;
        };
      }
    >({
      query: ({ payload, projectId }) => ({
        url: `/api/project/${projectId}/tracker/yandex/connect`,
        method: 'POST',
        body: payload,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Проект успешно подключен к Яндекс.Трекер`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['ProjectDetail'],
    }),
    connectTrackerToProject: builder.mutation<
      unknown,
      {
        projectId: string;
        tracker: INTEGRATION_PROVIDER;
        payload: ConnectToTrackerMap[INTEGRATION_PROVIDER];
      }
    >({
      query: ({ projectId, tracker, payload }) => ({
        url: `/api/project/${projectId}/tracker/${tracker.toLowerCase()}/connect`,
        method: 'POST',
        body: payload,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Проект успешно подключен трекеру`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['ProjectDetail'],
    }),
    disconnectTrackerFromProject: builder.mutation<unknown, DisconnectTrackerPayload>({
      query: ({ projectId, tracker }) => ({
        url: `/api/project/${projectId}/tracker/${tracker.toLowerCase()}/disconnect`,
        method: 'POST',
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Трекер отключен`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['ProjectDetail'],
    }),
    disconnectYandexTrackerToProject: builder.mutation<
      ResponseBody<Api.operations['disconnectProjectToYandexTracker']>,
      PathParameters<Api.operations['disconnectProjectToYandexTracker'], 'projectId'>
    >({
      query: (projectId) => ({
        url: `/api/project/${projectId}/tracker/yandex/disconnect`,
        method: 'POST',
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar(`Проект успешно отключен от Яндекс.Трекер`, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['ProjectDetail'],
    }),

    getProjectQueues: builder.query<
      any[],
      PathParameters<Api.operations['listQueue'], 'projectId'>
    >({
      query: (projectId) => ({
        url: `/api/tracker/project/${projectId}/queues`,
        method: 'GET',
      }),
      providesTags: ['ProjectQueues'],
    }),

    getProjectTypes: builder.query<
      any[],
      Api.operations['listTaskType']['parameters']['path'] &
        Api.operations['listTaskType']['parameters']['query']
    >({
      query: ({ projectId, queueId }) => ({
        url: `/api/tracker/project/${projectId}/task-types`,
        method: 'GET',
        params: { queueId },
      }),
      providesTags: ['ProjectTypes'],
    }),

    getProjectPriorities: builder.query<
      any[],
      Api.operations['listPriority']['parameters']['path']
    >({
      query: ({ projectId }) => ({
        url: `/api/tracker/project/${projectId}/priorities`,
        method: 'GET',
      }),
      providesTags: ['ProjectPriorities'],
    }),

    getProjectExecutors: builder.query<
      TrackerExecutor[],
      Api.operations['executorList']['parameters']['path']
    >({
      query: ({ projectId }) => ({
        url: `/api/tracker/project/${projectId}/executors`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseBody<Api.operations['executorList']>) => {
        return plainToInstance(TrackerExecutor, response);
      },
      providesTags: ['ProjectExecutors'],
    }),

    getWIPAnalytic: builder.query<
      WIPAnalyticsResponse[],
      Api.operations['getWorkInProgressAnalytic']['parameters']
    >({
      query: (params) => ({
        url: `/analytic/${params.path.projectId}/workInProgress`,
        method: 'GET',
        params: params.query,
      }),
    }),

    getWastedTimeAnalytic: builder.query<
      WastedTimeAnalyticResponse[],
      Api.operations['getWastedTimeAnalytic']['parameters']
    >({
      query: (params) => ({
        url: `/analytic/${params.path.projectId}/wastedTime`,
        method: 'GET',
        params: params.query,
      }),
    }),

    getLeadTimeAnalytic: builder.query<
      LeadTimeAnalyticResponse[],
      Api.operations['getLeadTimeAnalytic']['parameters']
    >({
      query: (params) => ({
        url: `/analytic/${params.path.projectId}/leadTime`,
        method: 'GET',
        params: params.query,
      }),
    }),

    getEffectivenessAnalytic: builder.query<
      EffectivenessAnalyticResponse[],
      Api.operations['getEffectivenessAnalytic']['parameters']
    >({
      query: (params) => ({
        url: `/analytic/${params.path.projectId}/effectiveness`,
        method: 'GET',
        params: params.query,
      }),
    }),

    getCycleTimeAnalytic: builder.query<
      CycleTimeAnalyticResponse[],
      Api.operations['getCycleTimeAnalytic']['parameters']
    >({
      query: (params) => ({
        url: `/analytic/${params.path.projectId}/cycleTime`,
        method: 'GET',
        params: params.query,
      }),
    }),

    getBurnoutAnalytic: builder.query<
      BurnoutAnalyticResponse[],
      Api.operations['getBurnoutAnalytic']['parameters']
    >({
      query: (params) => ({
        url: `/analytic/${params.path.projectId}/burnout`,
        method: 'GET',
        params: params.query,
      }),
    }),

    getThroughputAnalytic: builder.query<
      ThroughputAnalyticResponse[],
      Api.operations['getThroughputAnalytic']['parameters']
    >({
      query: (params) => ({
        url: `/analytic/${params.path.projectId}/throughput`,
        method: 'GET',
        params: params.query,
      }),
    }),
    getProjectTransitions: builder.query<
      ResponseBody<Api.operations['getAvailableTransitionsForProject']>,
      PathParameters<Api.operations['getAvailableTransitionsForProject'], 'projectId'>
    >({
      query: (projectId) => ({
        url: `/api/project/${projectId}/transitions`,
        method: 'GET',
      }),
      providesTags: ['ProjectTransitions'],
    }),
    makeProjectTransition: builder.mutation<
      ResponseBody<Api.operations['executeProjectTransition']>,
      {
        projectId: string;
        body: {
          transitionId: string;
          prefix?: string;
          [name: string]: unknown;
        };
      }
    >({
      query: ({ projectId, body }) => ({
        url: `/api/project/${projectId}/transitions/execute`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProjectDetail', 'ProjectTransitions'],
    }),
    createProject: builder.mutation<
      ResponseBody<Api.operations['createRequest']>,
      RequestBody<Api.operations['createRequest']>
    >({
      query: (body) => ({
        url: '/api/project',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProjectList'],
    }),
    getProjectSummary: builder.query<
      CommonAnalytics,
      PathParameters<Api.operations['getProjectAnalytic'], 'projectId'>
    >({
      query: (project_id) => ({
        url: `/api/project/${project_id}/report/summary`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetTeamAnalyticQuery,
  useDetailProjectQuery,
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
  //Todo: useUpdateProjectMutation временно недоступен,
  // а useLazyGetProjectStatusesQuery cкорее всего придется убрать позже
  useUpdateProjectMutation,
  // useLazyGetProjectStatusesQuery,
  useLazyGetProjectQueuesQuery,
  useLazyGetProjectTypesQuery,
  useLazyGetProjectPrioritiesQuery,
  useLazyGetProjectExecutorsQuery,
  useGetWIPAnalyticQuery,
  useGetCycleTimeAnalyticQuery,
  useGetEffectivenessAnalyticQuery,
  useGetLeadTimeAnalyticQuery,
  useGetWastedTimeAnalyticQuery,
  useGetBurnoutAnalyticQuery,
  useGetThroughputAnalyticQuery,
  useGetProjectTransitionsQuery,
  useMakeProjectTransitionMutation,
  useConnectTrackerToProjectMutation,
  useDisconnectTrackerFromProjectMutation,
  useCreateProjectMutation,
  useGetProjectSummaryQuery,
} = projectsApi;
