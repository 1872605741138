import classNames from 'classnames';
import styles from '@pages/cloud/infrastructures/InfrastructureForm.module.scss';
import { Card } from '@mui/material';
import { useEffect } from 'react';
import {
  InfrastructureForm,
  InfrastructureFormProps,
} from '@pages/cloud/infrastructures/InfrastructureForm';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import {
  editInfrastructure,
  loadInfrastructures,
  selectDetailInfrastructure,
} from '@store/cloud/cloud.slice';
import { useNavigate, useParams } from 'react-router-dom';

export const EditInfrastructure = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { infrastructureId } = useParams();
  const infrastructure = useAppSelector((state) =>
    selectDetailInfrastructure(state, infrastructureId || ''),
  );

  useEffect(() => {
    dispatch(loadInfrastructures({}));
  }, [dispatch]);

  const submitInfrastructureForm: InfrastructureFormProps['submit'] = async (
    InfrastructureForm,
    form,
  ) => {
    try {
      await dispatch(
        editInfrastructure({
          id: infrastructureId,
          name: InfrastructureForm.name,
          organization: {
            resourceId: InfrastructureForm.organizationId,
            resourceName: InfrastructureForm.organizationName,
            resourceType: 'organization',
          },
          cloud: {
            //            id: InfrastructureForm.cloudResourceId,
            resourceId: InfrastructureForm.cloudId,
            resourceName: InfrastructureForm.cloudName,
            parentResourceId: InfrastructureForm.organizationId,
            resourceType: 'cloud',
          },
          folder: {
            //            id: InfrastructureForm.folderResourceId,
            resourceId: InfrastructureForm.folderId,
            resourceName: InfrastructureForm.folderName,
            resourceType: 'folder',
            parentResourceId: InfrastructureForm.cloudId,
          },
          network: {
            //            id: InfrastructureForm.networkResourceId,
            resourceId: InfrastructureForm.networkId,
            resourceName: InfrastructureForm.networkName,
            resourceType: 'network',
            parentResourceId: InfrastructureForm.folderId,
          },
          //serviceAccount: {resourceId:InfrastructureForm.serviceAccountId, resourceName: InfrastructureForm.serviceAccountName, resourceType: "service_account", parentResourceId: InfrastructureForm.folderId},
          delegationId: InfrastructureForm.delegationId,
        }),
      ).unwrap();
      navigate(`/clouds/infrastructures/`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  return (
    <div className={`col common_bc container-content`}>
      <div className="mt-4 d-flex gap-2">
        <NavigationBack back={{ to: '/clouds/infrastructures' }} />
      </div>
      <Card
        className={classNames('form-container', styles.form__card)}
        elevation={0}
        variant="outlined">
        <div className={'row'}>
          <div className={styles.form__page__container}>
            <div className={styles.form__page__title}>
              <h2 className={'form-title'}>
                Редактирование <br />
                инфраструктуры
              </h2>
            </div>
            <InfrastructureForm
              value={{
                id: infrastructureId || '',
                name: infrastructure?.name || '',
                organizationId: infrastructure?.organization?.resourceId || '',
                organizationName: infrastructure?.organization?.resourceName || '',
                cloudResourceId: infrastructure?.cloud?.id || '',
                cloudId: infrastructure?.cloud?.resourceId || '',
                cloudName: infrastructure?.cloud?.resourceName || '',
                folderResourceId: infrastructure?.folder?.id || '',
                folderId: infrastructure?.folder?.resourceId || '',
                folderName: infrastructure?.folder?.resourceName || '',
                networkResourceId: infrastructure?.network?.id || '',
                networkId: infrastructure?.network?.resourceId || '',
                networkName: infrastructure?.network?.resourceName || '',
                delegationId: infrastructure?.delegation?.id || '',
                svDelegationId: infrastructure?.delegation?.id || '',
              }}
              submit={submitInfrastructureForm}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
