import { useEffect } from 'react';
import { useAppDispatch } from '@hooks/index';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import dayjs from 'dayjs';
import { InputField } from '@components/MUI/InputField/InputField';
import { Button } from '@components/MUI/Button';
import styles from './ScheduleForm.module.scss';

import { FormField } from '@components/Form/FormField';
import { useParams } from 'react-router-dom';

export type ScheduleFormProps = {
  value: ScheduleFormValue;
  submit: (value: ScheduleFormValue, form: FormikBag<ScheduleFormProps, ScheduleFormValue>) => void;
};

type ScheduleFormValue = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
};

const ScheduleFormSchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  startDate: Yup.date()
    .required('Поле обязательно для заполнения')
    .min(dayjs().add(-1, 'day'), `Выберите дату от ${dayjs().format('DD.MM.YYYY')}`)
    .test('lessThanEndDate', 'Дата начала не должна превышать дату окончания', function (value) {
      if (this.parent.endDate && value) return dayjs(value) <= dayjs(this.parent.endDate);
      return true;
    }),
  endDate: Yup.date()
    .required('Поле обязательно для заполнения')
    .min(dayjs().add(-1, 'day'), `Выберите дату от ${dayjs().format('DD.MM.YYYY')}`)
    .test(
      'lessThanStartDate',
      'Дата окончания не должна быть меньше даты начала',
      function (value) {
        if (this.parent.startDate && value) return dayjs(value) >= dayjs(this.parent.startDate);
        return true;
      },
    ),
});

const FormComponent = () => {
  const form = useFormikContext<ScheduleFormValue>();

  const { scheduleId } = useParams();

  const dispatch = useAppDispatch();

  form.validationSchema = ScheduleFormSchema;

  useEffect(() => {}, [dispatch]);

  return (
    <Form onSubmit={form.handleSubmit}>
      <div className={styles.form__wrapper}>
        <InputField
          name="name"
          placeholder="Название расписания"
          type="text"
          className={styles.form__field}
        />
        <FormField
          name="startDate"
          type="datetime-local"
          placeholder="Дата начала"
          className="mb-4 input-classical"
          floating
          floatingLabelClassName="mb-3 req-label-custom"
        />
        <FormField
          name="endDate"
          type="datetime-local"
          placeholder="Дата окончания"
          className="mb-4 input-classical"
          floating
          floatingLabelClassName="mb-3 req-label-custom"
        />
      </div>
      <Button
        type="submit"
        disabled={!form.isValid}
        color="success"
        variant="contained"
        size="large"
        className={styles.submit__button}>
        {scheduleId ? 'Сохранить' : 'Создать'}
      </Button>
      <RequiredFieldsIndicator />
    </Form>
  );
};

export const ScheduleForm = withFormik<ScheduleFormProps, ScheduleFormValue>({
  displayName: 'ScheduleForm',
  mapPropsToValues: (props) => {
    return {
      id: props.value.id,
      name: props.value.name,
      startDate: props.value.startDate,
      endDate: props.value.endDate,
    };
  },
  enableReinitialize: true,
  validationSchema: ScheduleFormSchema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
